import * as React from "react";

import { useNavigate, useParams } from "react-router-dom";
import { Controller, SubmitHandler, useForm, ControllerRenderProps } from "react-hook-form";

import { ManageWrapper } from "layouts/manageLayout";
import { MainWrapper } from "layouts/mainWrapper/MainWrapper";
import { TextArea, TextInput, RadioButtons, Dropdown } from "ui-components";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import {
  getEmployee,
  postEmployee,
  putEmployee,
  getRoles,
  syncLdapEmployee,
  getWorkstationUpdatesForEmployee,
} from "../../redux/actions/employeeActions";
import { getFloors } from "../../redux/actions/floorActions";
import { getCompanies } from "../../redux/actions/companyActions";
import { getCompanyId, handleErrors } from "../../utils/helpers";
import { handleToast } from "../../utils/helpers";
import { SUCCESS_LDAP_SYNC, SUCCESS_POST, SUCCESS_PUT } from "../../constants/messageConstants";
import { Loader } from "../../components/loader/Loader";
import { EmployeeTypeEnum } from "../../enums/EmployeeTypeEnum";
import { useState } from "react";
import { WorkspaceUpdatesResponse, WorkspaceUpdatesResponseActionEnum } from "api/generated";
import { ReplacementTokensSection } from "./ReplacementTokensSection";
import { useDebounce } from "hooks";
import ConfirmationModal from "components/modal/ConfirmationModal";
import { useToastForInvalidCard } from "./useToastForInvalidCard";

type Inputs = {
  name: string;
  company: string;
  reason: string;
  status: string;
  note: string;
  floor: string;
  email: string;
  username: string;
  role: string;
  accessControlNumber: string;
};

export const NewEmployee: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [status, setStatus] = React.useState<string>("");
  const handleStatus = (value: string) => {
    setStatus(value);
  };
  const dispatch = useAppDispatch();
  const { companies } = useAppSelector((state) => state.company);
  const { isLoading, roles, employees } = useAppSelector((state) => state.employee);

  const {
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
    getValues,
  } = useForm<Inputs>();

  const showToastForInvalidCardIfNeeded = useToastForInvalidCard();

  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);

  const [workstationUpdates, setWorkstationUpdates] = useState<WorkspaceUpdatesResponse[]>([]);

  const statuses = [EmployeeTypeEnum.Office, EmployeeTypeEnum.Home, EmployeeTypeEnum.Inactive];

  const debouncedAcs = useDebounce(watch("accessControlNumber"));
  const employeesWithSameAcs = React.useMemo(
    () =>
      employees.filter(
        (e) => debouncedAcs?.length && e.accessControlNumber === debouncedAcs && id !== e.id?.toString(),
      ),
    [debouncedAcs, employees, id],
  );
  const numberOfEmployeesWithSameAcs = employeesWithSameAcs.length;

  const renderCofirmationContent = () => {
    if (!employeesWithSameAcs.length) return null;

    return (
      <>
        <p>Because provided 'Default ACS token' is already in use, we will remove it from:</p>
        <br />
        {employeesWithSameAcs.map((e) => (
          <p key={e.id}>
            <b>
              {e.name} | {e.company?.name}
            </b>
          </p>
        ))}
        <br />
        <br />
        <p>Continue with saving?</p>
      </>
    );
  };

  const saveEmployee: SubmitHandler<Inputs> = async (data) => {
    if (id !== undefined) {
      try {
        await dispatch(
          putEmployee({
            employeeId: Number(id),
            employeeName: data.name,
            additionalNote: data.note,
            employeeStatus: data.status,
            email: data.email,
            username: data.username,
            companyId: getCompanyId(data.company, companies),
            reasonForDeactivation: data.reason,
            role: data.role,
            accessControlNumber: data.accessControlNumber,
          }),
        ).unwrap();

        handleToast(SUCCESS_PUT, "success", 4000);
        showToastForInvalidCardIfNeeded(data.accessControlNumber);
        navigate(-1);
      } catch (error) {
        handleErrors(error, "with that username or email");
      }

      return;
    }

    try {
      await dispatch(
        postEmployee({
          employeeName: data.name,
          reasonForDeactivation: data.reason,
          additionalNote: data.note,
          employeeStatus: data.status,
          companyId: getCompanyId(data.company, companies),
          email: data.email,
          username: data.username,
          accessControlNumber: data.accessControlNumber,
        }),
      ).unwrap();

      handleToast(SUCCESS_POST, "success", 4000);
      showToastForInvalidCardIfNeeded(data.accessControlNumber);
      navigate(-1);
    } catch (error) {
      handleErrors(error, "with that username or email");
    }
  };

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (numberOfEmployeesWithSameAcs === 0) {
      saveEmployee(data);
      return;
    }

    setShowSaveConfirmation(true);
  };

  const handleLdapSync = (event: React.FormEvent) => {
    event.preventDefault();

    if (id === undefined) {
      return;
    }

    dispatch(syncLdapEmployee(parseInt(id)))
      .unwrap()
      .then(() => {
        handleToast(SUCCESS_LDAP_SYNC);
      })
      .catch((res) => {
        handleErrors(res, "with that username or email");
      });
  };

  const errorName = errors.name?.type === "required" ? "Employee name is required" : null;

  const errorCompany = errors.company?.type === "required" ? "Company is required" : null;

  const errorReason = errors.reason?.type === "required" ? "Reason is required" : null;

  const errorStatus = errors.status?.type === "required" ? "Status is required" : null;

  const errorEmail = errors.email?.type === "required" ? "Email is required" : null;

  const errorUsername = errors.username?.type === "required" ? "Username is required" : null;

  const errorRole = errors.role?.type === "required" ? "Role is required" : null;

  const rulesName = { required: true };

  const rulesCompany = { required: true };

  const rulesReason = {
    required: true,
  };
  const rulesRole = {
    required: true,
  };

  const rulesStatus = { required: true };

  const rulesNote = { required: false };
  const rulesEmail = { required: true };
  const rulesUsername = { required: true };

  const renderName = ({ field }: { field: ControllerRenderProps<Inputs, "name"> }) => {
    return <TextInput error={errorName} label="Employee name" placeholder="Enter an employee name..." {...field} />;
  };

  const renderCompany = ({ field }: { field: ControllerRenderProps<Inputs, "company"> }) => {
    return (
      <Dropdown
        error={errorCompany!}
        onChangeSelection={field.onChange}
        label="Select a company"
        placeholder={field.value !== "" ? field.value : "Select a company..."}
        options={companies.map((item) => item.name)}
        {...field}
      />
    );
  };

  const renderReason = ({ field }: { field: ControllerRenderProps<Inputs, "reason"> }) => {
    return (
      <TextInput
        error={errorReason}
        label="Reason for deactivation"
        placeholder="Enter a reason for deactivation..."
        {...field}
      />
    );
  };

  const renderEmail = ({ field }: { field: ControllerRenderProps<Inputs, "email"> }) => {
    return <TextInput error={errorEmail} label="Email" placeholder="Enter email..." type="email" {...field} />;
  };

  const renderUsername = ({ field }: { field: ControllerRenderProps<Inputs, "username"> }) => {
    return <TextInput error={errorUsername} label="Username" placeholder="Enter username" {...field} />;
  };

  const renderStatus = ({ field }: { field: ControllerRenderProps<Inputs, "status"> }) => {
    return (
      <RadioButtons
        error={errorStatus}
        onChangeSelection={field.onChange}
        setValue={handleStatus}
        label="Status"
        statuses={statuses}
        valueForEdit={status}
        {...field}
      />
    );
  };

  const renderRole = ({ field }: { field: ControllerRenderProps<Inputs, "role"> }) => {
    return (
      <Dropdown
        error={errorRole!}
        onChangeSelection={field.onChange}
        label="Select a role"
        placeholder={field.value !== "" ? field.value : "Select a role..."}
        options={roles}
        {...field}
      />
    );
  };

  const renderNote = ({ field }: { field: ControllerRenderProps<Inputs, "note"> }) => {
    return <TextArea label="Additional note" placeholder="Enter additional note..." {...field} />;
  };

  const renderDefaultAcsToken = ({ field }: { field: ControllerRenderProps<Inputs, "accessControlNumber"> }) => {
    return <TextInput error={null} label="Default ACS token" placeholder="Enter ACS token number" {...field} />;
  };

  const handleReason = () => {
    if (status === "Inactive") {
      return <Controller name="reason" control={control} defaultValue="" rules={rulesReason} render={renderReason} />;
    }

    return null;
  };

  const handleLoader = () => {
    if (isLoading) {
      return <Loader />;
    }

    return null;
  };

  const handleNavigate = () => {
    navigate(-1);
  };

  React.useEffect(() => {
    dispatch(getFloors());
    dispatch(getCompanies());
    dispatch(getRoles());
    if (id !== undefined) {
      dispatch(getEmployee(Number(id)))
        .unwrap()
        .then((res) => {
          setValue("name", res.name!);
          setValue("email", res.email!);
          setValue("username", res.username!);
          setValue("note", res.additionalNote!);
          setValue("company", res.company?.name!);
          setValue("floor", res.workstation?.room?.floor?.nameForAdmins!);
          setValue("reason", res.reasonForDeactivation!);
          setValue("status", res.status!);
          setValue("role", res.role!);
          setStatus(res?.status!);
          setValue("accessControlNumber", res.accessControlNumber ?? "");
        });
      dispatch(getWorkstationUpdatesForEmployee(Number(id)))
        .unwrap()
        .then(setWorkstationUpdates);
    }
  }, [dispatch, id, setValue]);

  const headerText: string = id ? "Manage the new employee" : "Add a new employee";

  const getActionText = (action: WorkspaceUpdatesResponseActionEnum) => {
    switch (action) {
      case "DETACH":
        return "Unassigned from table";
      case "ATTACH":
        return "Assigned to table";
    }
  };

  return (
    <ManageWrapper buttonText={headerText} manageText="an employee" navigateTo="" isButtonVisible={false}>
      <MainWrapper title={headerText}>
        {handleLoader()}
        <form onSubmit={handleSubmit(onSubmit)} className="form">
          <div className="form__row">
            <Controller name="name" control={control} defaultValue="" rules={rulesName} render={renderName} />
            <Controller name="company" control={control} defaultValue="" rules={rulesCompany} render={renderCompany} />
            <Controller name="email" control={control} defaultValue="" rules={rulesEmail} render={renderEmail} />
          </div>
          <div className="form__row">
            <Controller
              name="username"
              control={control}
              defaultValue=""
              rules={rulesUsername}
              render={renderUsername}
            />
            <Controller name="role" control={control} defaultValue="" rules={rulesRole} render={renderRole} />
            <Controller name="note" control={control} defaultValue="" rules={rulesNote} render={renderNote} />
          </div>
          <div className="form__row">
            <Controller name="status" control={control} defaultValue="" rules={rulesStatus} render={renderStatus} />
            {handleReason()}
            <Controller name="accessControlNumber" control={control} defaultValue="" render={renderDefaultAcsToken} />
          </div>

          <div className="form__row">
            <button type="submit" className="form__btn btn btn--green">
              Save
            </button>
            <button onClick={handleNavigate} type="button" className="form__btn btn btn--red">
              Cancel
            </button>
            {id && (
              <button onClick={handleLdapSync} type="button" className="form__btn btn btn--orange">
                Sync LDAP
              </button>
            )}
          </div>

          {id && <ReplacementTokensSection employeeId={Number(id)} />}

          <br />
          <label className="form__label" htmlFor="acs_table">
            Workstation changes
          </label>
          {workstationUpdates.length ? (
            <div className="form__row">
              <table className="table">
                <thead>
                  <tr className="table__row">
                    <th className="table__head">Location</th>
                    <th className="table__head">Type</th>
                    <th className="table__head">Action</th>
                    <th className="table__head">Date</th>
                    <th className="table__head">Performed by</th>
                  </tr>
                </thead>
                <tbody>
                  {workstationUpdates.map((item) => {
                    return (
                      <tr className="table__row" key={item.id}>
                        <td className="table__cell">{item.location}</td>
                        <td className="table__cell">{item.employeeType}</td>
                        <td className="table__cell">{getActionText(item.action ?? "ATTACH")}</td>
                        <td className="table__cell">
                          {new Date(Number(item.createdOn ?? 0) * 1000).toLocaleDateString("sr-RS", {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </td>
                        <td className="table__cell">{item.actionPerformedByEmployeeName}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : null}
        </form>
        <ConfirmationModal
          show={showSaveConfirmation}
          handleClose={() => setShowSaveConfirmation(false)}
          handleConfirm={() => {
            saveEmployee(getValues());
          }}>
          {renderCofirmationContent()}
        </ConfirmationModal>
      </MainWrapper>
    </ManageWrapper>
  );
};
